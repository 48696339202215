import { NotificationFCMDTO, NotificationType } from "@/dto/notifications/NotificationFCM.dto";
import { makeBinded } from "@/proxies";
import { Profile } from "@amministro-io-packages/auth-interfaces";

class FCMHandlerService {
  /**
   * Dato un tipo di notifica, ritorna la querty serializzata con le info necessarie per essere gestita
   */
  buildNotificationQuery(data: NotificationFCMDTO["data"]): string {
    const { notificationType, profileID, entityIDs, notificationBody } = data;

    switch (notificationType) {
      /**
       * Ingressi: necessario specificare il body della notifica
       */
      case "new_sys_entry_from_org_spl_usr_to_org_usr":
      case "new_sys_entry_from_spl_to_spl":
        return new URLSearchParams({ notificationType, profileID, entityIDs, notificationBody }).toString();

      /**
       * Ordini: necessario specificare la categoria
       */
      case "new_order_assigned_from_org_to_spl":
      case "new_order_chat_message_from_org_spl_to_org_spl":
      case "order_askedprice_rejected_from_org_to_spl":
      case "order_askedprice_setted_from_spl_to_org":
        return new URLSearchParams({ notificationType, profileID, entityIDs }).toString();

      default:
        return new URLSearchParams({ notificationType, profileID, entityIDs }).toString();
    }
  }

  /**
   * Data un tipo do notifica, ritorna il tipo di interfaccia (il prodotto) da aprire
   * @param notificationType
   * @returns
   */
  appInterfaceFromNotification(notification: {
    notificationType?: NotificationType;
    profile: Profile;
  }): "interfaccia_amministroio" | "interfaccia_archivioplus" | "interfaccia_notificoonline" | "interfaccia_administration" {
    switch (notification.notificationType) {
      case "new_qual_chat_message_from_sys_spl_to_sys_spl": {
        switch (notification.profile.company_type) {
          case "sys_systemAdmins":
            return "interfaccia_administration";
          default:
            return "interfaccia_amministroio";
        }
      }
      default:
        return "interfaccia_amministroio";
    }
  }
}

const fcmHandlerService = makeBinded(FCMHandlerService);

export { fcmHandlerService, FCMHandlerService };
