import { dialogService } from "@/services/dialog.service";

document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
window.addEventListener("resize", () => {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
});
// TODO: troppi import statici -> convertire ad import dinamici dove possibile

/**
 * BARE IMPORTS
 */
import "@/assets/tailwind.css";
import "@/assets/skeleton-loader.css";
import "@/i18n/yup";
import "@/validations/yup";

/**
 * PLUGINS
 * @see { loadPlugins }
 */
import { envPlugin } from "@/plugins/env";
import { ctxPlugin } from "@/plugins/context";
import { piniaPlugin } from "@/plugins/pinia";
import routerPlugin from "@/router";
import { vuetify } from "@/plugins/vuetify";
import { createGmapVuePlugin } from "@gmap-vue/v3";

/**
 * DIRECTIVES
 * @see { loadDirectives }
 */
import { intersect } from "@/directives/intersect";
import { clickOutside } from "@/directives/click-outside";
import { ripple } from "@/directives/ripple";

/**
 * SHARED COMPONENTS
 * @see { loadSharedComponents }
 */
import SharedAvatar from "@/components/_shared/SharedAvatar.vue";
import SharedAlert from "@/components/_shared/SharedAlert.vue";
import SharedBack from "@/components/_shared/SharedBack.vue";
import SharedBtn from "@/components/_shared/SharedBtn.vue";
import SharedComboBox from "@/components/_shared/SharedComboBox.vue";
import SharedLabeledText from "@/components/_shared/SharedLabeledText.vue";
import SharedLoading from "@/components/_shared/SharedLoading.vue";
import SharedLazyIterator from "@/components/_shared/SharedLazyIterator.vue";
import SharedModal from "@/components/_shared/SharedModal.vue";
import SharedNotFound from "@/components/_shared/SharedNotFound.vue";
import SharedOverlay from "@/components/_shared/SharedOverlay.vue";
import SharedProgressCircular from "@/components/_shared/SharedProgressCircular.vue";
import SharedRefresh from "@/components/_shared/SharedRefresh.vue";
import SharedScrollList from "@/components/_shared/SharedScrollList.vue";
import SharedSwitch from "@/components/_shared/SharedSwitch.vue";
import SharedTable from "@/components/_shared/SharedTable.vue";
import SharedTeleport from "@/components/_shared/SharedTeleport.vue";
import SharedTextInput from "@/components/_shared/SharedTextInput.vue";
import SharedTooltip from "@/components/_shared/SharedTooltip.vue";
import SharedTextTruncated from "@/components/_shared/SharedTextTruncated.vue";
import Dialog from "@/components/_shared/Dialog.vue";

/**
 * SERVICES
 */
import { errorService } from "@/services/error.service";

/**
 * LIB
 */
import { pipe } from "@/lib/functions";

/**
 * LAYOUTS
 * @see { loadLayouts }
 */
import PrivateLayout from "@/components/layouts/LayoutPrivate.vue";
import PublicLayout from "@/components/layouts/LayoutPublic.vue";
import ErrorLayout from "@/components/layouts/LayoutError.vue";

/**
 * SENTRY
 * */
import * as Sentry from "@sentry/vue";
import { SentryConfig } from "../../../config/sentry";

/**
 * APP
 * @see { mountApp }
 */
import App from "./App.vue";

import { createApp } from "vue";
import { versionListener } from "@/services/versionListener";
const app = createApp(App);

Sentry.init({
  app,
  ...SentryConfig.setStrategy(process.env["VUE_APP_ENV"] as "development" | "staging" | "production").get(),
});

function config() {
  app.config.errorHandler = (err) => {
    errorService.notifyError(err);
    Sentry.captureException(err);
  };
}

function loadSharedComponents() {
  app.component("shared-alert", SharedAlert);
  app.component("shared-avatar", SharedAvatar);
  app.component("shared-back", SharedBack);
  app.component("shared-btn", SharedBtn);
  app.component("shared-combobox-input", SharedComboBox);
  app.component("shared-labeled-text", SharedLabeledText);
  app.component("shared-lazy-iterator", SharedLazyIterator);
  app.component("shared-loading", SharedLoading);
  app.component("shared-modal", SharedModal);
  app.component("shared-not-found", SharedNotFound);
  app.component("shared-overlay", SharedOverlay);
  app.component("shared-progress-circular", SharedProgressCircular);
  app.component("shared-refresh", SharedRefresh);
  app.component("shared-scroll-list", SharedScrollList);
  app.component("shared-switch", SharedSwitch);
  app.component("shared-table", SharedTable);
  app.component("shared-teleport", SharedTeleport);
  app.component("shared-text-input", SharedTextInput);
  app.component("shared-tooltip", SharedTooltip);
  app.component("shared-text-truncated", SharedTextTruncated);
  app.component("base-dialog", Dialog);
}

function loadPlugins() {
  app.use(piniaPlugin);
  app.use(ctxPlugin);
  app.use(envPlugin);
  app.use(routerPlugin);
  app.use(vuetify);
  app.use(
    createGmapVuePlugin({
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_APIKEY!,
        libraries: "places,drawing",
      },
    })
  );
}

function loadLayouts() {
  app.component("private-layout", PrivateLayout);
  app.component("public-layout", PublicLayout);
  app.component("error-layout", ErrorLayout);
}

function loadDirectives() {
  app.directive("m_intersect", intersect);
  app.directive("click-outside", clickOutside);
  app.directive("ripple", ripple);
}

function mountApp() {
  app.mount("#app");
}

/**
 * The public page of a building is {{clientBaseUrl}}/buildings.html?id={{id}}
 * QR codes redirect to {{clientBaseUrl}}/buildings/{{id}}. This page belongs to the
 * index page of Client and thus requires authentication. Users not authenticated will
 * be redirected to Go.
 */
const _url = new URL(window.location.href);
const _to = _url.searchParams.get("to");
const getPathSegments = (url: string) => new URL(url).pathname.substring(1).split("/");

if (_to && getPathSegments(_to)[0] === "buildings") {
  const id = getPathSegments(_to)[1];
  window.open(`${process.env.VUE_APP_AMMINISTROIO_URL}/buildings.html?id=${id}`, "_self");
} else {
  pipe(config, loadPlugins, loadDirectives, loadSharedComponents, loadLayouts, mountApp)();
}

versionListener.init(() => {
  dialogService
    .showDialog(
      {
        title: "Attenzione",
        description: `È disponibile una nuova versione di ${process.env.VUE_APP_PRODUCT_DISPLAY_NAME}. Per continuare, ricarica la pagina.`,
        successButtonText: "Ricarica",
        errorButtonText: "",
      },
      false
    )
    .then(() => location.reload());
});
